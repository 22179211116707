import { useState } from "react";
import styles from "./MainModule.module.scss";
import processLine from "assets/processLine.svg";
import logo from "assets/unshared/carLogo.svg";
import discounts from "assets/discounts.png";
import fidelity from "assets/fidelity.png";
import orient from "assets/orient.png";
import sukoon from "assets/sukoon.png";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Button from "ui/button";
const MainModule = () => {
    const [phoneInputValue, setPhoneInputValue] = useState("");
    const handleChangePhoneInput = (v: string) => {
        setPhoneInputValue(v);
    };

    return (
        <div className={styles.container}>
            <img alt={"process"} src={processLine} />
            <div className={styles.content}>
                <div className={styles.contentLogoRow}>
                    <img src={logo} alt={"logo"} />
                    <img className={styles.discountImg} src={discounts} alt={"discounts"} />
                </div>
                <div className={styles.textRow}>
                    Compare insurance quotes with top insurers for low rates
                </div>
                <div className={styles.inputRow}>
                    <PhoneInput
                        country={"ae"}
                        value={phoneInputValue}
                        onChange={handleChangePhoneInput}
                        inputProps={{
                            maxLength: 16
                        }}
                        inputClass={styles.phoneInput}
                        onlyCountries={["ae"]}
                        placeholder={"Phone number"}
                    />
                    <Button disabled={phoneInputValue.length < 12} withShieldAndArrow={false}>
                        Get quotes
                    </Button>
                </div>
                <div className={styles.infoContent}>
                    <div className={styles.infoText}>Trusted by</div>
                    <div className={styles.images}>
                        <img src={fidelity} alt={"fidelity"} />
                        <img src={orient} alt={"orient"} />
                        <img src={sukoon} alt={"sukoon"} />
                    </div>
                </div>
            </div>
        </div>
    );
};
export default MainModule;
