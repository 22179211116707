import "./styles/global.scss";
import Footer from "components/footer";
import Header from "components/header";
import MainModule from "module/mainModule";
const App = () => {
    return (
        <div className={"app"}>
            <Header />
            <MainModule />
            <Footer />
        </div>
    );
};
export default App;
