import { FC } from "react";
import licenceEagle from "assets/licenseEagle.png";

import styles from "./Footer.module.scss";
import { brandName } from "consts";

const Footer: FC = () => {
    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <div className={styles.text}>
                    The {brandName} names, logos, product names, feature names, and slogans are
                    trademarks owned by or licensed to {brandName} Motor Co. Ltd.
                </div>
                <div className={styles.licenses}>
                    <img src={licenceEagle} alt={"licence"} />
                    <p className={styles.licenceText}>
                        Licensed By Central Bank of UAE License Number 900
                    </p>
                </div>
            </div>
        </div>
    );
};
export default Footer;
