import styles from "./Header.module.scss";
import logo from "assets/whiteLogo.svg";

const Header = () => {
    return (
        <div className={styles.header}>
            <img className={styles.logo} src={logo} alt={"logo"} />
            <div>
                <span className={styles.locale}>EN</span>
                <span className={styles.divider}> / </span>
                <span className={styles.locale}>عربي</span>
            </div>
        </div>
    );
};
export default Header;
